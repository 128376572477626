import { api } from ":mods";
import { ENDPOINTS } from "../const";

export async function getBlog() {

      return api
		.get(ENDPOINTS.BLOG_LIST)
		.then(async (res) => {
                  const dataList  = res.data;
			if (!dataList) return Promise.reject();
			return Promise.resolve(dataList);
		})
		.catch((err) => {})

}

export async function getBlogPost(id:number) {

      return api
		.get(ENDPOINTS.BLOG_DETAILS+`${id}/`)
		.then(async (res) => {
                  const dataList  = res.data;
			if (!dataList) return Promise.reject();
			return Promise.resolve(dataList);
		})
		.catch((err) => {})

}